export type State = {
  isOpen: boolean
  children?: JSX.Element
}

export type Action =
  | {
      type: 'openRightBar'
      payload: { isOpen: boolean; children?: JSX.Element }
    }
  | { type: 'closeRightBar' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'openRightBar':
      return {
        ...action.payload,
      }
    case 'closeRightBar': {
      return {
        isOpen: false,
      }
    }
  }
}

export const initialState = {
  isOpen: false,
}
