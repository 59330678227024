import React, { useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import DropDownMenu from '@/components/Parts/DropDownMenu'
import { LangIcon, LangMenu } from '@/layout/LangSelector'
import { UserIcon, UserMenu } from '@/layout/User'
import { ServiceIcon, ServiceMenu } from '@/layout/ServiceMenu'
import { useLeftBar } from '@/hooks'
import SideNav from '@/layout/SideNav'
import Error from '@/layout/Error'

type Props = {
  refNavBurger: React.RefObject<HTMLDivElement>
}

const Header = ({ refNavBurger }: Props) => {
  const { isOpenLeftBar, closeLeftBar, openLeftBar } = useLeftBar()

  useEffect(() => {
    // For gatsby build
    if (window != null) {
      if (window.innerWidth < 680) {
        closeLeftBar()
      } else {
        openLeftBar(<SideNav />)
      }
    }
  }, [])

  const toggleNav = () => {
    if (isOpenLeftBar) {
      closeLeftBar()
    } else {
      openLeftBar(<SideNav />)
    }
  }

  return (
    <div className="z-50">
      <header className="fixed w-full bg-gray-900 border-b outline-none focus:outline-none shadow-lg">
        <nav className="flex items-center mx-auto  py-2 px-2 md:px-8">
          <div
            ref={refNavBurger}
            className="text-2xl mr-2 cursor-pointer text-white"
            onClick={toggleNav}
          >
            <FaBars />
          </div>
          <h1 className="hidden md:block text-2xl text-center mr-6 text-blue-400 font-bold">
            QUALITIA DOMAIN
          </h1>
          <div className="ml-auto h-full flex items-center justify-left">
            <DropDownMenu Icon={LangIcon} Menu={LangMenu} />
            <div className="flex items-center ml-4">
              <DropDownMenu Icon={ServiceIcon} Menu={ServiceMenu} />
            </div>
            <div className="flex items-center ml-4">
              <DropDownMenu Icon={UserIcon} Menu={UserMenu} />
            </div>
          </div>
        </nav>
        <Error />
      </header>
    </div>
  )
}

export default Header
