import React from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import Overlay from '@/components/Overlay'
import useTranslation from '@/i18n'
import { useLoader } from '@/hooks'

const Loader = () => {
  const { showLoader } = useLoader()
  const { t } = useTranslation()

  return (
    <>
      {showLoader && (
        <Overlay backgroundColor={'rgba(255,255,255,.8)'}>
          <div className="text-center">
            <h1 className="text-gray-800 text-2xl font-bold mb-4">
              {t('Now processing')}
            </h1>
            <p className="text-gray-800 text-lg mb-4">{t('LoadingAndWait')}</p>

            <PulseLoader
              color={'#10B981'}
              loading={true}
              css={`
                display: block;
                margin: 0 auto;
                border-color: #10b981;
              `}
              size={16}
            />
          </div>
        </Overlay>
      )}
    </>
  )
}

export default Loader
