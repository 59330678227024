import {
  useContext,
  useState,
  useEffect,
  useCallback,
  MutableRefObject,
  useRef,
} from 'react'
import { AppContext } from '@/contexts'

export function useWaitSignIn<T>() {
  const { isAuth } = useAuth()

  return (func: () => T) => {
    const intervalId = setInterval(() => {
      if (isAuth) {
        clearInterval(intervalId)
        func()
      }
    }, 50)
  }
}

let _loaderTimeoutId: NodeJS.Timeout | null = null

export function useLoader() {
  const { state, dispatch } = useContext(AppContext)
  const { setErr } = useErr()

  const enableLoader = () => {
    dispatch({ type: 'enableLoader' })
    _loaderTimeoutId = setTimeout(() => {
      dispatch({ type: 'disableLoader' })
      setErr('UnkownAndRetry')
    }, 200000)
  }

  const disableLoader = () => {
    if (_loaderTimeoutId != null) {
      clearTimeout(_loaderTimeoutId)
      _loaderTimeoutId = null
    }
    dispatch({ type: 'disableLoader' })
  }

  if (state == null || state.loader == null) {
    return { showLoader: false, enableLoader, disableLoader }
  }

  return { showLoader: state.loader.showLoader, enableLoader, disableLoader }
}

let _progTimeoutId: NodeJS.Timeout | null = null

export function useProgress() {
  const { state, dispatch } = useContext(AppContext)
  const { enableLoader, disableLoader } = useLoader()

  const start = (wait: number = 4000) => {
    if (_progTimeoutId == null) {
      _progTimeoutId = setTimeout(() => {
        if (_progTimeoutId != null) {
          enableLoader()
        }
      }, wait)
    }
    dispatch({ type: 'start' })
  }

  const stop = () => {
    if (_progTimeoutId != null) {
      clearTimeout(_progTimeoutId)
      _progTimeoutId = null
    }

    disableLoader()
    dispatch({ type: 'stop' })
  }

  if (state == null || state.progress == null) {
    return { inProgress: false, showLoader: false, start, stop }
  }

  return {
    inProgress: state.progress.inProgress,
    start,
    stop,
  }
}

export function useRightBar() {
  const { state, dispatch } = useContext(AppContext)

  const openRightBar = (children: JSX.Element) => {
    if (window != null) {
      if (window.innerWidth < 680) {
        dispatch({ type: 'closeLeftBar' })
      }
    }
    dispatch({ type: 'openRightBar', payload: { isOpen: true, children } })
  }

  const closeRightBar = () => dispatch({ type: 'closeRightBar' })

  if (state == null || state.rightBar == null) {
    return { isOpenRightBar: false, openRightBar, closeRightBar }
  }

  return {
    isOpenRightBar: state.rightBar.isOpen,
    childrenRightBar: state.rightBar.children,
    openRightBar,
    closeRightBar,
  }
}

export function useModal() {
  const { state, dispatch } = useContext(AppContext)

  const openModal = (children: JSX.Element) =>
    dispatch({ type: 'openModal', payload: { isOpen: true, children } })

  const closeModal = () => dispatch({ type: 'closeModal' })

  if (state == null || state.rightBar == null) {
    return { isOpenModal: false, openModal, closeModal }
  }

  return {
    isOpenModal: state.modal.isOpen,
    children: state.modal.children,
    openModal,
    closeModal,
  }
}

export function useLeftBar() {
  const { state, dispatch } = useContext(AppContext)

  const openLeftBar = (children: JSX.Element) => {
    if (window != null) {
      if (window.innerWidth < 680) {
        dispatch({ type: 'closeRightBar' })
      }
    }
    dispatch({ type: 'openLeftBar', payload: { isOpen: true, children } })
  }

  const closeLeftBar = () => dispatch({ type: 'closeLeftBar' })

  if (state == null || state.leftBar == null) {
    return { isOpenLeftBar: false, openLeftBar, closeLeftBar }
  }

  return {
    isOpenLeftBar: state.leftBar.isOpen,
    childrenLeftBar: state.leftBar.children,
    openLeftBar,
    closeLeftBar,
  }
}

export function useAuth() {
  const { state, dispatch } = useContext(AppContext)

  const signIn = () => dispatch({ type: 'signin' })
  const signOut = () => dispatch({ type: 'signout' })

  if (state == null || state.auth == null) {
    return { isAuth: false, signIn, signOut }
  }

  return { isAuth: state.auth.isAuth, signIn, signOut }
}

export function useErr() {
  const { state, dispatch } = useContext(AppContext)

  const setErr = (message: string, status?: number, params?: any) =>
    dispatch({ type: 'addErr', status, message, params })
  const removeErr = (id: string) => dispatch({ type: 'removeErr', id })

  if (state == null || state.error == null) {
    return { errors: [], setErr, removeErr }
  }

  return { errors: state.error.errors, setErr, removeErr }
}

export function useTooltip() {
  const { state, dispatch } = useContext(AppContext)

  const setTooltip = (message: string) =>
    dispatch({ type: 'setTooltip', message })
  const clearTooltip = () => dispatch({ type: 'clearTooltip' })

  if (state == null || state.tooltip == null) {
    return { show: false, setTooltip, clearTooltip }
  }

  return {
    show: state.tooltip.show,
    message: state.tooltip.message,
    setTooltip,
    clearTooltip,
  }
}

export function useTrial() {
  const { state, dispatch } = useContext(AppContext)

  const trial = (expireDate: Date) => dispatch({ type: 'trial', expireDate })
  const disableTrial = () => dispatch({ type: 'disableTrial' })

  if (state == null || state.tooltip == null) {
    return { isTrial: false, trial, disableTrial }
  }

  return {
    isTrial: state.trial.isTrial,
    expireDate: state.trial.expireDate,
    trial,
    disableTrial,
  }
}

export function useLimitedMode() {
  const { state, dispatch } = useContext(AppContext)

  const limitedMode = () => dispatch({ type: 'limitedMode' })

  if (state == null || state.limitedMode == null) {
    return { isLimitedMode: false, limitedMode }
  }

  return {
    isLimitedMode: state.limitedMode.isLimitedMode,
    limitedMode,
  }
}

export function useHeight() {
  const [height, setHeight] = useState(0)
  const { isOpenRightBar } = useRightBar()
  const { isOpenLeftBar } = useLeftBar()

  useEffect(() => {
    const resizeEvent = () => {
      if (window != null) {
        setHeight(window.innerHeight)
      }
    }

    if (window != null) {
      setHeight(window.innerHeight)
      window.addEventListener('resize', resizeEvent)
    }

    return () => {
      if (window != null) {
        window.removeEventListener('resize', resizeEvent)
      }
    }
  }, [isOpenLeftBar, isOpenRightBar])

  return { height }
}

export function useSafeState<T>(defaultValue: T): [T, (v: T) => void] {
  const [state, changeState] = useState(defaultValue)
  let mounted = useRef(true)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const wrapChangeState = useCallback((v: T) => {
    mounted.current && changeState(v)
  }, [])

  return [state, wrapChangeState]
}
