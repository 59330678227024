/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/main.css'

// You can delete this file if you're not using it
import React from 'react'
import i18n from '@/i18n/config'
import { I18nextProvider } from 'react-i18next'
import { Layout } from '@/layout'
import Context from '@/contexts'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  return (
    <Context value={props}>
      <Layout>{element}</Layout>
    </Context>
  )
}
