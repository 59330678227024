import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import ChangePassword from '@/layout/User/ChangePassword'
import Signout from '@/layout/User/SignOut'

export const UserIcon = () => {
  return <FaUserCircle className="text-3xl text-white" />
}

export const UserMenu = ({ close }: { close?: () => void }) => {
  return (
    <>
      <ChangePassword />
      <Signout close={close} />
    </>
  )
}
