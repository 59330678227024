import React from 'react'
import Overlay from '@/components/Overlay'
import { useAuth, useModal } from '@/hooks'
import { GrClose } from 'react-icons/gr'

const Modal = () => {
  const { isOpenModal, children, closeModal } = useModal()
  const { isAuth } = useAuth()

  const close = () => {
    if (isAuth) {
      closeModal()
    }
  }

  return (
    <>
      {isOpenModal && (
        <Overlay click={() => {}}>
          <div className="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50">
            <div className="m-0">
              <GrClose className="cursor-pointer m-1" onClick={close} />
              {children}
            </div>
          </div>
        </Overlay>
      )}
    </>
  )
}

export default Modal
