import React, { useState, useEffect, useRef } from 'react'
import Modal from '@/components/Parts/Modal'

type ComponentsProps = {
  ref?: React.RefObject<HTMLDivElement>
  close?: () => void
}

type Props = {
  Icon: (props: ComponentsProps) => JSX.Element
  Menu: (props: ComponentsProps) => JSX.Element
}

const DropDownMenu = ({ Icon, Menu }: Props) => {
  const [isOpen, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const ddRef = useRef<HTMLDivElement>(null)
  const dialogRef = useRef<HTMLDivElement>(null)

  const check = (divRef: React.RefObject<HTMLDivElement>, event: any) => {
    return (
      divRef != null &&
      divRef.current != null &&
      !divRef.current.contains(event.target)
    )
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        check(menuRef, event) &&
        check(dialogRef, event) &&
        check(ddRef, event)
      ) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  const click = () => setOpen(!isOpen)

  return (
    <>
      <div className="relative inline-block text-left h-full">
        <div
          onClick={click}
          className="flex items-center h-full cursor-pointer"
          ref={menuRef}
        >
          <Icon />
        </div>
        {isOpen && (
          <div
            ref={ddRef}
            className="hidden md:block origin-top-right absolute right-0 mt-2 py-2 w-56 rounded-md border border-gray-400 shadow-sm bg-white ring-1 ring-black ring-opacity-5 z-50"
          >
            <div className="px-2 md:px-0">
              <Menu close={() => setOpen(false)} />
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="md:hidden block" ref={dialogRef}>
          <Modal close={click}>
            <div className="px-2 py-4">
              <Menu />
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default DropDownMenu
