import React, { useEffect } from 'react'
import { FaTimesCircle } from 'react-icons/fa'
import { useErr, useLimitedMode } from '@/hooks'
import useTranslation from '@/i18n'

const Error = () => {
  const { errors, removeErr } = useErr()
  const { t } = useTranslation()
  const { limitedMode } = useLimitedMode()

  return (
    <>
      {errors.map((error, index) => {
        if (error.status === 422 && error.message === 'Payment required') {
          limitedMode()
        }
        return (
          <div
            className="w-full bg-red-600 py-4 px-10 border-b outline-none focus:outline-none border-gray-100"
            key={index}
          >
            <div className="w-full text-center">
              <div className="inline-block">
                <div className="flex items-center">
                  <FaTimesCircle
                    className="text-white text-2xl cursor-pointer mr-2"
                    onClick={() => removeErr(error.id)}
                  />
                  {error.status != null && (
                    <p className="text-white text-sm text-left mr-2">
                      StatusCode: {error.status}
                    </p>
                  )}
                  {error.message != null && (
                    <p className="text-white text-sm w-80 break-words text-left">
                      {t(error.message, error.params)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Error
