import React, { useReducer, ReactNode, useEffect } from 'react'
import { auth, getSessionId, removeSessionId } from '@/auth'
import { reducer, initialState, AppContext } from './reducer'
import { useAuth } from '@/hooks'
// import { useTranslation } from 'react-i18next'
import useTranslation from '@/i18n'

export * from './reducer'

export function useWaitSignIn<T>() {
  const { isAuth } = useAuth()

  return (func: () => T) => {
    const intervalId = setInterval(() => {
      if (isAuth) {
        clearInterval(intervalId)
        func()
      }
    }, 50)
  }
}

type Props = {
  value: any
  children: ReactNode
}

export const Context = ({ children, value }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { i18n } = useTranslation()

  if (i18n.language !== value.pageContext.lang) {
    i18n.changeLanguage(value.pageContext.lang)
  }

  useEffect(() => {
    const sessionId = getSessionId(location)

    auth(sessionId)
      .then(() => {
        const removedSession = removeSessionId(location.href)
        if (removedSession !== location.href) {
          history.pushState(null, '', removeSessionId(location.href))
        }

        dispatch({ type: 'signin' })
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <AppContext.Provider value={{ state, dispatch, ...value }}>
      {state.auth.isAuth && children}
    </AppContext.Provider>
  )
}
