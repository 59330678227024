module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"defaultCrumb":{"location":{"state":{"crumbClicked":false},"pathname":"/"},"crumbLabel":"","crumbSeparator":"","crumbStyle":{"color":"#666"},"crumbActiveStyle":{"color":"#333"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"QUALITIA DNS","shortName":"qt-dns","startUrl":"/","backgroundColor":"#663399","themeColor":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9c3701527a5a527490d2c396710ecb0f"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://855a4deea8954dde9e7b11230e960636@o822045.ingest.sentry.io/5831399"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
