export type State = {
  isLimitedMode: boolean
}

export type Action = { type: 'limitedMode' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'limitedMode':
      return {
        isLimitedMode: true,
      }
  }
}

export const initialState = {
  isLimitedMode: false,
}
