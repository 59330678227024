import React, { ReactNode, useRef, useEffect } from 'react'
import Header from '@/layout/Header'
import Main from '@/layout/Main'
import RightBar from '@/layout/RightBar'
import LeftBar from '@/layout/LeftBar'
import Loader from '@/layout/Loader'
import Tooltip from '@/layout/Tooltip'
import Modal from '@/layout/Modal'
import { useHeight, useRightBar, useLeftBar, useLimitedMode } from '@/hooks'
import LimitedMode from './LimitedMode'

interface Props {
  children: ReactNode
}

export const Layout = ({ children }: Props) => {
  const refNavBurger = useRef(null)
  const { height } = useHeight()
  const { isOpenRightBar } = useRightBar()
  const { isOpenLeftBar, closeLeftBar } = useLeftBar()
  const { isLimitedMode } = useLimitedMode()

  useEffect(() => {
    if (window != null) {
      if (window.innerWidth < 680) {
        if (isOpenRightBar && isOpenLeftBar) {
          closeLeftBar()
        }
      }
    }
  }, [isOpenLeftBar, isOpenRightBar])

  return (
    <>
      {!isLimitedMode && (
        <>
          <div
            className={`relative flex h-full ${
              isOpenRightBar ? 'overflow-hidden sm:overflow-visible' : ''
            }`}
          >
            <Header refNavBurger={refNavBurger} />
            <LeftBar refNavBurger={refNavBurger} height={`${height}px`} />
            <Main>{children}</Main>
            <RightBar height={`${height}px`} />
          </div>
          <Tooltip />
          <Modal />
          <Loader />
        </>
      )}
      <LimitedMode />
    </>
  )
}
