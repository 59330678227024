export type State = {
  isAuth: boolean
}

export type Action = { type: 'signin' } | { type: 'signout' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'signin':
      return {
        isAuth: true,
      }
    case 'signout': {
      return {
        isAuth: false,
      }
    }
  }
}

export const initialState = {
  isAuth: false,
}
