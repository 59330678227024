import React, { useState } from 'react'
import useTranslation from '@/i18n'
import { FaSignOutAlt } from 'react-icons/fa'
import { useAuth, useModal } from '@/hooks'
import { startSingleSignOut } from '@/auth'

const Confirm = () => {
  const { signOut } = useAuth()
  const { t } = useTranslation()
  const [processing, setProcessing] = useState(false)

  const click = async () => {
    setProcessing(true)
    signOut()
    await startSingleSignOut()
  }

  return (
    <div className="">
      <div className="px-4 ">
        <div className="flex items-center text-gray-700 text-xl">
          <FaSignOutAlt className="mr-1" />
          <h1 className=" font-bold">{t('Sign Out')}</h1>
        </div>
        <p className="mt-2 text-gray-900 ">{t('ConfirmSignOut')}</p>
      </div>

      <div className="mt-4 text-right bg-gray-100 px-2 py-2">
        <button
          disabled={processing}
          className={`text-white font-bold w-20 py-1 rounded-md cursor-pointe ml-auto text-sm focus:outline-none ${
            processing ? 'bg-blue-200' : 'bg-blue-500'
          }`}
          autoFocus={true}
          onClick={click}
        >
          {t('Yes')}
        </button>
      </div>
    </div>
  )
}

const SignOut = ({ close }: { close?: () => void }) => {
  const { openModal } = useModal()
  const { t } = useTranslation()

  const confirm = () => {
    if (close != null) close()
    openModal(<Confirm />)
  }

  return (
    <>
      <div
        className="flex items-center hover:bg-blue-100 cursor-pointer px-4 py-2"
        onClick={confirm}
      >
        <FaSignOutAlt />
        <p className="px-2">{t('Sign Out')}</p>
      </div>
    </>
  )
}

export default SignOut
