// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-list-attribute-tsx": () => import("./../../../src/pages/contact-list/attribute.tsx" /* webpackChunkName: "component---src-pages-contact-list-attribute-tsx" */),
  "component---src-pages-contact-list-gtld-tsx": () => import("./../../../src/pages/contact-list/gtld.tsx" /* webpackChunkName: "component---src-pages-contact-list-gtld-tsx" */),
  "component---src-pages-domain-list-config-tsx": () => import("./../../../src/pages/domain-list/config.tsx" /* webpackChunkName: "component---src-pages-domain-list-config-tsx" */),
  "component---src-pages-domain-list-index-tsx": () => import("./../../../src/pages/domain-list/index.tsx" /* webpackChunkName: "component---src-pages-domain-list-index-tsx" */),
  "component---src-pages-domain-transfer-index-tsx": () => import("./../../../src/pages/domain-transfer/index.tsx" /* webpackChunkName: "component---src-pages-domain-transfer-index-tsx" */),
  "component---src-pages-failed-tsx": () => import("./../../../src/pages/failed.tsx" /* webpackChunkName: "component---src-pages-failed-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-domain-tsx": () => import("./../../../src/pages/search-domain.tsx" /* webpackChunkName: "component---src-pages-search-domain-tsx" */),
  "component---src-pages-signout-tsx": () => import("./../../../src/pages/signout.tsx" /* webpackChunkName: "component---src-pages-signout-tsx" */),
  "component---src-template-post-tsx": () => import("./../../../src/template/post.tsx" /* webpackChunkName: "component---src-template-post-tsx" */)
}

