import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { MdLanguage } from 'react-icons/md'

const langs = {
  en: {
    selected: 'English',
    ja: 'text-black hover:bg-blue-100',
    en: 'text-gray-500 cursor-default',
  },
  ja: {
    selected: '日本語',
    ja: 'text-gray-500 cursor-default',
    en: 'text-black hover:bg-blue-100',
  },
}

const Nav = ({
  orignalPath,
  whichLang,
  close,
}: {
  orignalPath: string
  whichLang: string
  close?: () => void
}) => {
  const lang = whichLang === 'ja' ? langs.ja : langs.en
  const onClose = () => {
    if (close != null) {
      close()
    }
  }

  return (
    <>
      <GatsbyLink
        aria-label={`Change language to ja`}
        to={`/ja/${orignalPath}${location.search}`}
        className={`w-full block px-4 py-2 ${lang.ja} `}
        onClick={onClose}
      >
        日本語
      </GatsbyLink>
      <GatsbyLink
        aria-label={`Change language to en`}
        to={`/en/${orignalPath}${location.search}`}
        className={`w-full block px-4 py-2 ${lang.en} `}
        onClick={onClose}
      >
        English
      </GatsbyLink>
    </>
  )
}

export const LangIcon = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const selected = pathname.split('/')[1] === 'ja' ? '日本語' : 'English'

  return (
    <>
      <MdLanguage className="text-white text-md" />
      <p className="hidden md:block ml-2 text-xs text-white">{selected}</p>
    </>
  )
}

type Props = {
  close?: () => void
}

export const LangMenu = ({ close }: Props) => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const originalPath = pathname
    .split('/')
    .slice(2)
    .join('/')

  return (
    <Nav
      orignalPath={originalPath}
      whichLang={pathname.split('/')[1]}
      close={close}
    />
  )
}
