import Auth from '@aws-amplify/auth'
import { redirectToSignIn } from './redirect'

let _jwtToken: null | string = null

export function refreshToken() {
  return new Promise<string | null | undefined>((resolve, reject) =>
    Auth.currentUserInfo()
      .then(() => {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            _jwtToken = user.signInUserSession.idToken.jwtToken
            resolve(_jwtToken)
          })
          .catch(() => {
            localStorage.clear()
            reject()
          })
      })
      .catch(() => {
        localStorage.clear()
        reject()
      })
  )
}

export function getToken() {
  return _jwtToken
}

export function withRefreshToken<T, S>(func: (arg?: S) => Promise<T>) {
  return (arg?: S) =>
    new Promise<T>((resolve, reject) =>
      refreshToken()
        .then(() =>
          func(arg)
            .then((res) => resolve(res))
            .catch((err) => reject(err))
        )
        .catch(() => {
          redirectToSignIn()
          reject()
        })
    )
}
