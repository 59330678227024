import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useTooltip } from '@/hooks'
import useTranslation from '@/i18n'

const ToolChip = () => {
  const { show, message, clearTooltip } = useTooltip()
  const [top, setTop] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    if (show) {
      timeoutId = setTimeout(() => {
        clearTooltip()
      }, 3000)
    }

    if (window != null && document != null) {
      setTop(window.pageYOffset + document.documentElement.clientHeight - 80)
    }

    return () => {
      if (show && timeoutId != null) {
        clearTimeout(timeoutId)
      }
    }
  }, [show])

  return (
    <>
      {show && (
        <div
          className="absolute w-10/12 px-4 sm:w-64 rounded-md h-12 bg-gray-800 text-white z-50 slideinR2L flex items-center "
          style={{ top: `${top}px`, right: '10px' }}
        >
          <FaTimes className="mr-2 cursor-pointer" onClick={clearTooltip} />
          <p>{t(message!)}</p>
        </div>
      )}
    </>
  )
}

export default ToolChip
