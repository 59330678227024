import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import useTranslation from '@/i18n'

const Link = ({ to, ...rest }) => {
  const { i18n } = useTranslation()
  return <GatsbyLink {...rest} to={`/${i18n.language}${to}`} />
}

export const ALink = ({ to, ...rest }) => {
  const { i18n } = useTranslation()

  if (to.startsWith('https://') || to.startsWith('http://')) {
    return <a {...rest} href={to} />
  }

  return <a {...rest} href={`/${i18n.language}${to}`} />
}

export default Link
