type formatOptinsType = {
  [key: string]: {
    [key: string]: {
      [key: string]: string | number | boolean | object
    }
  }
}

const options: formatOptinsType = {
  date: {
    en: {
      ymd: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      },
      ymdhms: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC',
      },
      hms: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC',
      },
    },
    ja: {
      ymd: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      },
      ymdhms: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC',
      },
      hms: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC',
      },
    },
  },
  number: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'name',
      },
    },
    ja: {
      currency: {
        style: 'currency',
        currency: 'JPY',
        currencyDisplay: 'name',
      },
    },
  },
}

export default options
