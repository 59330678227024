import { axios, getAuthorization } from '@/api/axios'

export const get = (signOutId?: string): Promise<boolean> =>
  new Promise<boolean>((resolve, reject) => {
    axios
      .get<boolean>(`${process.env.QT_ACCOUNT_API_URL}/signout`, {
        headers: getAuthorization(),
        params: {
          signOutId,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(false)
      })
  })

export const post = (): Promise<{ signOutId: string }> =>
  new Promise<{ signOutId: string }>((resolve, reject) => {
    axios
      .post<{ signOutId: string }>(
        `${process.env.QT_ACCOUNT_API_URL}/signout`,
        {},
        {
          headers: getAuthorization(),
        }
      )
      .then((result) => {
        resolve(result.data)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
