import { Auth } from 'aws-amplify'
import {
  CognitoUserSession,
  ICognitoUserSessionData,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js'
import urlJoin from 'url-join'
import { axios } from '@/api/axios'
import * as queryString from 'query-string'
import { refreshToken } from '@/auth/token'
import { get, post } from '@/api/signout'
import { redirectToSignIn } from './redirect'

type sessionInfo = {
  userName: string
  idToken: string
  accessToken: string
  refreshToken: string
  userPoolId: string
  userPoolWebClientId: string
}

export const _getJwt = (sessionId: string): Promise<sessionInfo> => {
  const query = `sessionId=${sessionId}`
  return new Promise<sessionInfo>((resolve, reject) => {
    axios
      .get<sessionInfo>(`${process.env.QT_ACCOUNT_API_URL}/token?${query}`)
      .then((result) => {
        resolve(result.data)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}

function _saveSessionToLocal(session: sessionInfo) {
  const Pool = new CognitoUserPool({
    UserPoolId: session.userPoolId,
    ClientId: session.userPoolWebClientId,
  })

  const cognitoUser = new CognitoUser({
    Username: session.userName,
    Pool,
  })

  const sessionData: ICognitoUserSessionData = {
    IdToken: new CognitoIdToken({ IdToken: session.idToken }),
    AccessToken: new CognitoAccessToken({ AccessToken: session.accessToken }),
    RefreshToken: new CognitoRefreshToken({
      RefreshToken: session.refreshToken,
    }),
  }

  const userSession = new CognitoUserSession(sessionData)

  cognitoUser.setSignInUserSession(userSession)
}

export function getSessionId(location: any) {
  const { sessionId } = queryString.parse(location.search)

  if (typeof sessionId === 'object') return null

  return sessionId
}

export async function auth(sessionId?: string | null) {
  return new Promise<void>((resolve, reject) => {
    if (sessionId == null) {
      return refreshToken()
        .then(() => resolve())
        .catch(() => {
          redirectToSignIn()
          reject()
        })
    } else {
      return _getJwt(sessionId)
        .then((session) => {
          _saveSessionToLocal(session)
          refreshToken()
            .then(() => resolve())
            .catch(() => {
              redirectToSignIn()
              reject()
            })
        })
        .catch(() => {
          redirectToSignIn()
          reject()
        })
    }
  })
}

export async function startSingleSignOut() {
  const res = await post().catch(() => undefined)

  if (res == null) {
    location.replace('/failed')
    return
  }

  location.replace(
    `${process.env.QT_ACCOUNT_URL!}/signout?signOutId=${res.signOutId}`
  )
}

export async function signOut(services: string[], signOutId?: string) {
  const token = await refreshToken().catch(() => undefined)

  if (signOutId === undefined) {
    location.replace('/failed')
    return
  }

  if (token != null) {
    if (!(await get(signOutId).catch(() => false))) {
      location.replace('/failed')
      return
    }

    await Auth.signOut().catch(() => undefined)
    localStorage.clear()
  }

  if (services.length === 0) {
    // All done
    location.replace(process.env.QT_ACCOUNT_URL!)
    return
  }

  const nextService = services.shift()

  const queryParameter =
    services.length > 0
      ? `?serviceList=${encodeURIComponent(
          services.join(',')
        )}&signOutId=${signOutId}`
      : `?signOutId=${signOutId}`

  location.replace(urlJoin(`https://${nextService}/signout`, queryParameter))
}
