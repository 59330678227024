import React, { ReactNode } from 'react'
import { useLeftBar } from '@/hooks'

type MainProps = {
  children: ReactNode
}

const Main = ({ children }: MainProps) => {
  const { isOpenLeftBar } = useLeftBar()

  return (
    <>
      <div className="absolute md:static w-full h-full">
        <div
          className={`px-2 py-16 md:px-8 ${isOpenLeftBar ? 'sm:ml-56' : ''}`}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default Main
