import React, { createContext } from 'react'
import * as Modal from './modal'
import * as Auth from './auth'
import * as Progress from './progress'
import * as ErrorMsg from './errorMsg'
import * as RightBar from './rightBar'
import * as LeftBar from './leftBar'
import * as Loader from './loader'
import * as Tooltip from './tooltip'
import * as Trial from './trial'
import * as LimitedMode from './limitedMode'

type State = {
  modal: Modal.State
  auth: Auth.State
  progress: Progress.State
  error: ErrorMsg.State
  rightBar: RightBar.State
  leftBar: LeftBar.State
  loader: Loader.State
  tooltip: Tooltip.State
  trial: Trial.State
  limitedMode: LimitedMode.State
}

export type Action =
  | Modal.Action
  | Auth.Action
  | Progress.Action
  | ErrorMsg.Action
  | RightBar.Action
  | LeftBar.Action
  | Loader.Action
  | Tooltip.Action
  | Trial.Action
  | LimitedMode.Action

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'limitedMode':
      const { limitedMode, ...rest } = state
      return {
        limitedMode: LimitedMode.reducer(action),
        ...rest,
      }
    case 'trial':
    case 'disableTrial': {
      const { trial, ...rest } = state
      return {
        trial: Trial.reducer(action),
        ...rest,
      }
    }
    case 'setTooltip':
    case 'clearTooltip': {
      const { tooltip, ...rest } = state
      return {
        tooltip: Tooltip.reducer(action),
        ...rest,
      }
    }
    case 'openModal':
    case 'closeModal': {
      const { modal, ...rest } = state
      return {
        modal: Modal.reducer(action),
        ...rest,
      }
    }
    case 'openRightBar':
    case 'closeRightBar': {
      const { rightBar, ...rest } = state
      return {
        rightBar: RightBar.reducer(action),
        ...rest,
      }
    }
    case 'openLeftBar':
    case 'closeLeftBar': {
      const { leftBar, ...rest } = state
      return {
        leftBar: LeftBar.reducer(action),
        ...rest,
      }
    }
    case 'signout':
    case 'signin': {
      const { auth, ...rest } = state
      return {
        auth: Auth.reducer(action),
        ...rest,
      }
    }
    case 'start':
    case 'stop': {
      const { progress, ...rest } = state
      return {
        progress: Progress.reducer(action),
        ...rest,
      }
    }
    case 'enableLoader':
    case 'disableLoader': {
      const { loader, ...rest } = state
      return {
        loader: Loader.reducer(action),
        ...rest,
      }
    }
    case 'addErr':
    case 'removeErr': {
      const { error, ...rest } = state
      return {
        error: ErrorMsg.reducer(error, action),
        ...rest,
      }
    }
    default:
      return state
  }
}

export const AppContext = createContext(
  {} as {
    state: State
    dispatch: React.Dispatch<Action>
  }
)

export const initialState = {
  modal: Modal.initialState,
  auth: Auth.initialState,
  progress: Progress.initialState,
  error: ErrorMsg.initialState,
  rightBar: RightBar.initialState,
  leftBar: LeftBar.initialState,
  loader: Loader.initialState,
  tooltip: Tooltip.initialState,
  trial: Trial.initialState,
  limitedMode: LimitedMode.initialState,
}
