import React, { ReactNode } from 'react'
import Overlay from '@/components/Overlay'

type Props = {
  children: ReactNode
  close: () => void
}

const Modal = ({ children, close }: Props) => {
  return (
    <Overlay click={close}>
      <div className="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50">
        <div className="m-0">{children}</div>
      </div>
    </Overlay>
  )
}

export default Modal
