import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { FaGlobeEurope } from 'react-icons/fa'
import JWT from 'jwt-decode'
import { getToken } from '@/auth'
import { FaUser } from 'react-icons/fa'
import { RiContactsLine } from 'react-icons/ri'
import Link from '@/components/Parts/Link'
import { useSafeState } from '@/hooks'

const SideNav = () => {
  const { t, i18n } = useTranslation()
  const [user, setUser] = useSafeState('')
  const [selected, setSelected] = useSafeState('')

  useEffect(() => {
    const token = getToken()

    if (token != null) {
      const jwt: { email: string } = JWT(token)

      setUser(jwt['email'])
    }

    const labels = window.location.pathname.split('/').slice(1)
    const paths = i18n.languages.includes(labels[0]) ? labels.slice(1) : labels

    setSelected(paths.join('/'))
  }, [])

  return (
    <>
      <div className="px-8 sm:px-2 py-8 w-full sm:w-56">
        <div className="flex items-center text-xs mb-4 border-b outline-none focus:outline-none border-gray-300 px-1 text-gray-600">
          <FaUser className="mr-2" />
          <p className="py-1 font-bold w-full truncate">{user}</p>
        </div>
        <Link
          className={`px-2 py-2 flex items-center text-gray-700 cursor-pointer  hover:bg-blue-100 ${
            selected.startsWith('domain-list')
              ? 'sm:bg-blue-100 rounded-md'
              : ''
          }`}
          to={'/domain-list'}
          onClick={() => setSelected('domain-list')}
        >
          <FaGlobeEurope className="mr-2" />
          <p>{t('Domain List')}</p>
        </Link>

        <div className="px-2 py-2 flex items-center text-gray-700">
          <RiContactsLine className="mr-2" />
          <p>{t('Contact List')}</p>
        </div>

        <Link
          className={`px-8 py-2 flex items-center text-gray-700 cursor-pointer  hover:bg-blue-100 ${
            selected.startsWith('contact-list/gtld')
              ? 'sm:bg-blue-100 rounded-md'
              : ''
          }`}
          to={'/contact-list/gtld'}
          onClick={() => setSelected('contact-list/gtld')}
        >
          <p>{t('JP/GTLD domains')}</p>
        </Link>

        <Link
          className={`px-8 py-2 flex items-center text-gray-700 cursor-pointer  hover:bg-blue-100 ${
            selected.startsWith('contact-list/attribute')
              ? 'sm:bg-blue-100 rounded-md'
              : ''
          }`}
          to={'/contact-list/attribute'}
          onClick={() => setSelected('contact-list/attribute')}
        >
          <p>{t('Attribute domains')}</p>
        </Link>
      </div>
    </>
  )
}

export default SideNav
