import React, { useState } from 'react'
import useTranslation from '@/i18n'
import { FaKey } from 'react-icons/fa'
import { redirectToSignIn } from '@/auth'

const ChangePassword = () => {
  const [redirect, setRedirect] = useState(false)
  const { t } = useTranslation()

  return (
    <div
      className={`flex items-center ${
        redirect ? 'bg-blue-100' : ''
      } hover:bg-blue-100 cursor-pointer px-4 py-2`}
      onClick={() => {
        setRedirect(!redirect)
        redirectToSignIn('/change-password')
      }}
    >
      <FaKey />
      <p className="ml-2">{t('Change password')}</p>
    </div>
  )
}

export default ChangePassword
