export type State = {
  isTrial: boolean
  expireDate?: Date
}

export type Action =
  | { type: 'trial'; expireDate: Date }
  | { type: 'disableTrial' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'trial':
      return {
        isTrial: true,
        expireDate: action.expireDate,
      }
    case 'disableTrial': {
      return {
        isTrial: false,
      }
    }
  }
}

export const initialState = {
  isTrial: false,
}
