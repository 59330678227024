import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { useProgress, useRightBar } from '@/hooks'

type MainProps = {
  height: string
}

const RightBar = ({ height }: MainProps) => {
  const { inProgress } = useProgress()
  const { isOpenRightBar, closeRightBar, childrenRightBar } = useRightBar()
  return (
    <>
      {isOpenRightBar && (
        <>
          <div
            className={
              'fixed bg-white border-r shadow-lg w-full sm:w-auto z-40 pt-20 right-0'
            }
            style={{ height }}
          >
            <FaTimes
              className={`ml-2 cursor-pointer ${
                inProgress ? 'text-gray-400' : 'text-gray-900'
              }`}
              onClick={() => {
                if (!inProgress) {
                  closeRightBar()
                }
              }}
            />
            {childrenRightBar}
          </div>
        </>
      )}
    </>
  )
}

export default RightBar
