import React from 'react'
import Overlay from '@/components/Overlay'
import { useAuth, useLimitedMode, useModal } from '@/hooks'
import useTranslation from '@/i18n'

const Modal = () => {
  const { isLimitedMode } = useLimitedMode()
  const { t } = useTranslation()

  const click = () => {
    location.replace(process.env.REDIRECT_APPLY_PRODUCTION!)
  }

  return (
    <>
      {isLimitedMode && (
        <Overlay click={() => {}} backgroundColor="rgba(0,0,0,.9)">
          <div className="h-auto p-0 m-0 text-left bg-white rounded shadow-xl z-50 w-80">
            <div className="p-2">
              <h1 className="text-red-600 font-bold text-2xl mb-2">
                {t('Limited mode')}
              </h1>
              <p className="text-gray-600 font-medium mb-2">
                {t('LimitedModeDescription')}
              </p>
              <button
                className="bg-blue-500 text-white w-full mt-2 cursor-pointer"
                onClick={click}
              >
                {t('Go to Customer Portal')}
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </>
  )
}

export default Modal
