import React from 'react'
import { useLeftBar } from '@/hooks'

type LeftBarProps = {
  height: string
  refNavBurger: React.RefObject<HTMLDivElement>
}

const LeftBar = ({ height }: LeftBarProps) => {
  const { isOpenLeftBar, childrenLeftBar } = useLeftBar()

  return (
    <>
      {isOpenLeftBar && (
        <div
          className={
            'fixed bg-white border-r shadow-sm w-full sm:w-56 z-40 pt-12 slidein'
          }
          style={{ height }}
        >
          {childrenLeftBar}
        </div>
      )}
    </>
  )
}

export default LeftBar
