import React from 'react'
import useTranslation from '@/i18n'
import { FaThLarge } from 'react-icons/fa'

export const ServiceIcon = () => {
  return <FaThLarge className="text-white" />
}

export const ServiceMenu = () => {
  const { t } = useTranslation()

  return (
    <>
      <a
        href={process.env.QT_ORDER_URL}
        className="py-2 px-4 block w-full hover:bg-blue-100 cursor-pointer"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {t('Customer Portal')}
      </a>
    </>
  )
}
