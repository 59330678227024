import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getToken } from '@/auth'

axios.defaults.baseURL = process.env.QT_DOMAIN_API_URL

axiosRetry(axios, {
  retries: 4,
  retryDelay: (retryCount) => {
    return 2 ** retryCount * 1000
  },
})

function getAuthorization() {
  const token = getToken()

  if (process.env.LOCAL_DEV != null) {
    return undefined
  }

  return {
    Authorization: `Bearer ${token}`,
  }
}

export { axios, getAuthorization }
