export type State = {
  showLoader: boolean
}

export type Action = { type: 'enableLoader' } | { type: 'disableLoader' }

export const reducer = (action: Action) => {
  switch (action.type) {
    case 'enableLoader':
      return {
        showLoader: true,
      }
    case 'disableLoader': {
      return {
        showLoader: false,
      }
    }
  }
}

export const initialState = {
  showLoader: false,
}
